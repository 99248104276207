/* GENERALS */

.cursor-pointer {
  cursor: pointer !important;
}

.border-light {
  border-color: #dee2e673 !important;
}

.small-10 {
  font-size: 10px;
}

.small-10+br+span {
  font-size: 14px;
}

.rotate-90-down {
  transition: all 0.1s linear;
  transform: rotate(90deg);
}

.rotate-90-up {
  transition: all 0.1s linear;
  transform: rotate(-90deg);
}

.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

/* END GENERAL */

body {
  padding-right: 0px !important;
  min-height: 100vh;
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  position: relative;
}

#root>main {
  padding-bottom: 80px;
}

#root>footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 57px;
  line-height: 16px;
}

main.main-content {
  margin-top: 3rem;
}

/* .home-container{
  flex-direction: row-reverse;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  border-radius: 16px;
  /* box-shadow: 0px 1px 10px -3px #00000024; */
  box-shadow: 0px 4px 36px -23px #00000020;
  /* box-shadow: none; */
}

.btn {
  border-radius: 6px;
}

/* @media screen and (max-width: 767px){
  .home-container{
    flex-direction: column;
  }
  .menu-list-options{
    margin-left: 0px;
    flex-direction: row;
  }
  .menu-list-options div{
    margin: 0px 5px 30px 5px;
  }
} */

select.form-control.is-valid,
.was-validated select.form-control:valid,
select.form-control.is-invalid,
.was-validated select.form-control:invalid {
  background-position: right calc(0.800em + 0.1875rem) center !important;
}

div.NGselect.form-control {
  padding: 0px;
  border-width: 0px;
}

div.NGselect.form-control>div:nth-child(3) {
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
  border-color: inherit !important;
  border-radius: inherit;
  box-shadow: none !important;
}

div.NGselect.form-control>div:nth-child(3)>div>div {
  color: #6c757d !important;
}

div.NGselect.form-control>div:nth-child(3)+div {
  z-index: 2;
}

.custom-control+div[class*="valid-feedback"] {
  display: block;
}

.limit-text-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px;
  /* fallback */
  height: calc(22px * 3);
  /*fallback */
  /* max-height: calc(22px * 3);     // fallback */
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.limit-text-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px;
  /* fallback */
  height: calc(22px * 2);
  /*fallback */
  /* max-height: calc(22px * 3);     // fallback */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-gray {
  color: #92939b !important;
}

form label,
div.form-group label {
  font-size: 14px;
  color: rgb(41, 41, 41);
}

.z-index-0 {
  z-index: 0 !important;
}

.custom-control.custom-checkbox input {
  height: 0px;
  width: 0px;
}

input[disabled],
select[disabled],
textarea[disabled] {
  border: none !important;
  background-color: #f9f9f9 !important;
}

input[type="checkbox"][disabled]+.custom-control-label::before {
  border: none !important;

}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6c757d !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c757d !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6c757d !important;
}

select option[value=""] {
  color: #6c757d !important;
}

.Calendar div[role="gridcell"] {
  border-radius: 50rem !important;
}

.Calendar div[role="gridcell"]:first-child {
  /* color: var(--cl-color-disabled)!important; */
  background: transparent !important;
  cursor: default !important;
}

.table.form tbody tr td>div {
  padding: 0px !important;
  margin: 0px !important;
}

.table.form tbody tr td>div input {
  /* border-color: #ebebeb !important; */
  font-size: 15px !important;
  padding-left: 5px;
  padding-right: 5px;
}

.width-80px {
  width: 80px !important;
}

.width-100px {
  width: 100px !important;
}

.width-130px {
  width: 130px !important;
}

.zoom-hover-in {
  transition: all 0.15s linear;
  cursor: pointer;
}

.zoom-hover-in:hover {
  /* transform: scale(1.05); */
  transform: translateY(-5px);
  border-color: #ff9400 !important;
}

.admin-body *[class*="custom-control"],
.admin-body *[class*="input-group"] {
  z-index: 0;
}

input.manual-validation.is-invalid+.error-message {
  display: block !important;
}

.modal-content {
  border-radius: 15px;
}